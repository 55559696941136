<template>
    <div>
      <h1>Blog List</h1>
      <div class="blogs-container">
          <div v-for="blog in blogs" :key="blog._id" class="blog-item">
            <router-link :to="'/blog/'+blog._id" class="blog-content">
              <img class="blog-image" :src="`${sdk_url}${blog.Image.path}`" alt="">
              <div class="blog-text">
                <h2>{{ blog.Story_Name }}</h2>
                <p class="author-name">By {{ blog.Author_Name }}</p>
                <p class="short-desc">{{ shortDesc(blog.Desc) }}</p>
              </div>
            </router-link>
          </div>
      </div>
    </div>
</template>

<script>
    export default{
         data() {
            return {
                blogs:[],
                sdk_url:'https://bijit.xyz'
            }
        },
        methods:{
            getBlogs(){
                if(localStorage.getItem('authenticated')){
                  const options = {
                  method: 'GET',
                  headers: {Authorization: 'Bearer 738ebf1f7e4c7b976cdfd51ee8b84d'}
                };

                fetch(`${this.sdk_url}/cockpit-master/api/collections/get/Blog`, options)
                  .then(response => response.json())
                  .then(response =>{ 
                        this.blogs = response.entries;
                    })
                  .catch(err => console.error(err));
                }
            },
            shortDesc(desc) {
              return desc.split(' ').slice(0, 10).join(' ') + '...'
            }
        },
        created(){
            this.getBlogs();
        }
    }
</script>
<style scoped>
  .blogs-container {
    padding: 1em;
  }
  h1{
    text-align: left;
    padding-left: 10px;
  }
  .blog-content {
    display: flex;
    align-items: center;
  }
  .blog-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-right: 20px;
  }
  .blog-text {
    flex: 1;
    text-align: center;
  }
  .author-name {
    font-style: italic;
  }
  .blog-item {
    transition: background-color 0.5s ease;
   }
   .blogs-container .blog-item:nth-of-type(even) {
        background-color: #f2f2f2;
   }
   .blogs-container .blog-item:nth-of-type(odd) {
        background-color: white;
   }
   .blog-item:hover {
        cursor: pointer;
        background-color: #f2f2f2;
   }
  /* Add media queries for responsiveness */
  @media only screen and (max-width: 768px) {
    .blog-image {
      width: 100px;
      height: 100px;
    }
  }
    @media only screen and (min-width: 768px) {
      .blog-item {
        padding: 50px;
      }
      h1{
        padding-left: 70px;
        padding-top: 50px;
      }
    }
</style>