<template>
<fvMain>
      <fvContent>
         <fvHeader class="transparent sticky-header">
                <span class="fv-padding-start" />
                <div class="logo fv-grow">
                    <router-link to="/">
                    <img src="../assets/logo.png">
                    </router-link>
                </div>
                <span class="fv-padding-start" />
                <fvButton v-if="isAuthenticated" v-on:click="logout" class="fv-button fv-size-sm fv-secondary">Logout</fvButton>
                <router-link v-if="!isAuthenticated" to="/login" class="fv-button login_btn fv-size-sm fv-secondary">Login</router-link>
        </fvHeader>
      </fvContent>
     <Toast :info="info"></Toast>
</fvMain>
</template>
<script>
    import Toast from '@/components/Toast.vue'
    export default{
        data() {
            return {
              authenticated: localStorage.getItem('authenticated'),
              info: {
                bool: false,
                time: 3000,
                msg: ''
              }
            }
        },
        components:{
            Toast
        },
        computed: {
            isAuthenticated() {
              return this.authenticated;
            }
        },
          watch: {
            isAuthenticated() {
              this.$forceUpdate()
            }
          },
        methods:{
            logout(){
                if(localStorage.getItem('authenticated')){
                    localStorage.removeItem('authenticated')
                    localStorage.removeItem('api_key')
                    localStorage.removeItem('username')
                    this.authenticated = localStorage.getItem('authenticated');
                    //redirect to login page
//                    this.$router.push('/login')
                }else{
                    alert("Already Logged Out");
                }
            }
        },
        created(){
            if(localStorage.getItem('username')){
                this.info.bool = true;
                this.info.msg = `Hi ${localStorage.getItem('username')}`
            }   
        }
    }
</script>
<style>
    body{
      padding:0px;
      margin:0px;
    }
    .logo{
        margin-left: 10%;
        transform: translateX(-50%);  
        padding: 0px;
    }
    .logo img{
        width: 3em;
    }
    .login_btn{
        margin-right: 5%;
    }
    .sticky-header {
        position: sticky;
        top: 0;
    }
</style>
