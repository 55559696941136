<template>
    <fvMain>
        <fvToast v-model="info.bool" :timeout="parseInt(info.time) || 0">
              {{info.msg}}
        </fvToast>
    </fvMain>
</template>
<script>
    export default{
        props: {
          info: {
            type: Object,
            required: true
          }
        }
    }
</script>