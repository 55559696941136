import Vue from 'vue'
import App from './App.vue'

import Framevuerk from 'framevuerk/dist/framevuerk.js'

// Framevuerk main style
// Also you can include this via <link rel="stylesheet"> in your template
import 'framevuerk/dist/framevuerk.css'

import router from './router'
// Activate
Vue.use(Framevuerk)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
